import { tns } from 'tiny-slider/src/tiny-slider';

export default function () {
    [...document.querySelectorAll('.carousel-tns')].forEach((el) => {
        const responsiveItems = JSON.parse(el.getAttribute('data-tns-responsive-items'));

        const setting = {
            container: el,
            items: responsiveItems.xs,
            navPosition: 'bottom',
            mouseDrag: true,
            slideBy: 'page',
            gutter: 30,
            controlsPosition: 'bottom',
            controlsText: ['<i class="icon icon-chevron-left"></i>', '<i class="icon icon-chevron-right"></i>'],
            responsive: {
                576: {
                    items: responsiveItems.sm,
                },
                768: {
                    items: responsiveItems.md,
                },
                992: {
                    items: responsiveItems.lg,
                },
                1200: {
                    items: responsiveItems.xl,
                },
                1600: {
                    gutter: responsiveItems.xl > 4 ? 30 : 95,
                },
            },
        };

        tns({ ...setting, ...JSON.parse(el.getAttribute('data-tns-setting')) });
    });

    [...document.querySelectorAll('[data-carousel-gallery]')].forEach((el) => {
        const slider = tns({
            container: el,
            items: 1,
            nav: false,
            mouseDrag: true,
            slideBy: 'page',
            controlsPosition: 'bottom',
            autoHeight: true,
            controlsText: ['<i class="icon icon-chevron-left"></i>', '<i class="icon icon-chevron-right"></i>'],
        });


        if (slider) {
            slider.events.on('dragEnd', (info) => {
                info.container.classList.remove('tns-slider-drag');
            });

            slider.events.on('dragMove', (info) => {
                info.container.classList.add('tns-slider-drag');
            });
        }
    });
}
