export default function () {
    // Set property vh
    const setPropertyVh = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    window.addEventListener('resize', setPropertyVh);
    setPropertyVh();

    // Set property scrollbarWidth
    const setPropertyScrollbarWidth = () => {
        // https://stackoverflow.com/questions/13382516/getting-scroll-bar-width-using-javascript

        const outer = document.createElement('div');
        outer.style.visibility = 'hidden';
        outer.style.width = '100px';
        outer.style.msOverflowStyle = 'scrollbar';
        document.body.appendChild(outer);
        const widthNoScroll = outer.offsetWidth;
        outer.style.overflow = 'scroll';

        // add inner div
        const inner = document.createElement('div');
        inner.style.width = '100%';
        outer.appendChild(inner);

        const widthWithScroll = inner.offsetWidth;

        // remove divs
        outer.parentNode.removeChild(outer);

        document.documentElement.style.setProperty('--scrollbar-width', `${widthNoScroll - widthWithScroll}px`);
    };

    window.addEventListener('resize', setPropertyScrollbarWidth);
    setPropertyScrollbarWidth();

    // Set header height
    const setPropertyStickyHeaderHeight = () => {
        const header = document.querySelector('.sticky-header');

        if (header) {
            document.documentElement.style.setProperty('--sticky-header-height', `${header.getBoundingClientRect().height}px`);
        }
    };

    window.addEventListener('headerResize', setPropertyStickyHeaderHeight);
    window.addEventListener('resize', setPropertyStickyHeaderHeight);
    setPropertyStickyHeaderHeight();
}
