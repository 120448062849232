export default function () {
    if (!window.location.hash) {
        return;
    }

    const el = document.querySelector('.mod_faqpage');

    if (!el) {
        return;
    }

    const item = el.querySelector(`[id="${window.location.hash.substring(1)}"]`);

    if (!item) {
        return;
    }

    item.parentElement.classList.add('faq-focus');
}
