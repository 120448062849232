import CartObserver from '../../../_helpers/cart-observer';

export default function () {
    CartObserver.subscribe((cart) => {
        // Update the cart total numbers
        Array.from(document.querySelectorAll('[data-cart-total]')).forEach((el) => {
            el.innerText = cart.items.reduce((total, item) => total + item.quantity, 0);
        });
    });
}
