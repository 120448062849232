import { throttle } from 'throttle-debounce';

export default function () {
    const rightColumn = document.querySelector('.layout-aside, .product-reader__aside');

    if (!rightColumn) {
        return;
    }

    const cart = rightColumn.querySelector('.mod_app_product_cart');
    const lastElementInRightColumn = rightColumn.querySelector('.box-aside.last');
    let isSticky = false;
    let isPlace;

    function initStickyCart() {
        isPlaceForCart();

        if (isPlace) {
            updateStickyCart();
        } else {
            removeStickyCart();
        }
    }

    function isPlaceForCart() {
        isPlace = (rightColumn.offsetTop + rightColumn.offsetHeight - lastElementInRightColumn.offsetTop - lastElementInRightColumn.offsetHeight) > cart.offsetHeight;
    }

    function updateStickyCart() {
        if (!isPlace) {
            return;
        }

        if (lastElementInRightColumn.getBoundingClientRect().height + lastElementInRightColumn.getBoundingClientRect().y < 0) {
            setStickyCart();
        } else {
            removeStickyCart();
        }
    }

    function setStickyCart() {
        isSticky = true;
        cart.classList.add('sticky-cart-in');
        cart.classList.remove('sticky-cart-out');
    }

    function removeStickyCart() {
        if (isSticky) {
            isSticky = false;
            cart.classList.remove('sticky-cart-in');
            cart.classList.add('sticky-cart-out');
        }
    }

    function initStickyCartMobile() {
        const cartObserver = new IntersectionObserver(((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting || entry.boundingClientRect.top < 0) {
                    document.body.classList.add('hide-mobile-product-cart');
                } else {
                    document.body.classList.remove('hide-mobile-product-cart');
                }
            });
        }), { root: null, threshold: 0.5 });

        cartObserver.observe(cart);
    }

    if (cart && lastElementInRightColumn) {
        window.addEventListener('scroll', throttle(100, () => {
            updateStickyCart();
        }));

        window.addEventListener('resize', initStickyCart);

        initStickyCart();
        initStickyCartMobile();
    }
}
