export default function () {
    function scrollTo(element) {
        element.scrollIntoView();
    }

    // Module article list
    const articleListSelects = document.querySelectorAll('select[data-go-to-article]');

    Array.from(articleListSelects).forEach((select) => {
        select.addEventListener('change', (event) => {
            const { value } = event.target;
            const id = value.substring(value.indexOf('#') + 1);
            const article = document.getElementById(id);
            if (article) {
                scrollTo(article);
            }
        });
    });
}
