import axios from '../../../_helpers/axios';
import GLightbox from 'glightbox/src/js/glightbox';
import {handleAjaxError} from '../../../_helpers/error-handler';

const hash = 'login-registration-modal';

function initLoginRegistration() {
    import('../../../login/login')
        .then(() => import('../../../registration/registration')
        .then(initLoginRegistrationModals))
    ;
}

function initLoginRegistrationModals() {
    const modalTrigger = document.querySelector('[data-login-registration-trigger]');
    const modalWrapper = document.querySelector('[data-login-registration-modal]');

    if (!modalWrapper) {
        return;
    }

    const modalWrapperContainer = modalWrapper.parentNode;
    const tabs = Array.from(modalWrapper.querySelectorAll('[data-login-registration-tab]'));
    const contents = Array.from(modalWrapper.querySelectorAll('[data-login-registration-content]'));

    if (tabs.length === 0 || contents.length === 0) {
        return;
    }

    function activate(el) {
        tabs.forEach((tab) => {
            if (tab === el) {
                tab.classList.add('tab__item--active');
            } else {
                tab.classList.remove('tab__item--active');
            }
        });

        contents.forEach((content) => {
            if (content.dataset.loginRegistrationContent === el.dataset.loginRegistrationTab) {
                content.classList.add('tab-content__item--active');
            } else {
                content.classList.remove('tab-content__item--active');
            }
        });
    }

    // Power up the tabs
    tabs.forEach((tab) => {
        tab.addEventListener('click', (e) => {
            e.preventDefault();
            activate(tab);
        });
    });

    // Activate the first tab
    activate(tabs[0]);

    const modal = GLightbox({
        elements: [
            {
                href: modalWrapperContainer.id,
                type: 'inline',
                width: '565px',
                height: 'auto',
            },
        ],
        touchNavigation: false,
        closeOnOutsideClick: false,
        onClose: () => {
            history.replaceState(null, null, window.location.pathname + window.location.search);
            moveElement(modalWrapperContainer, modalWrapper);
        },
        onOpen: () => {
            history.replaceState(null, null, `${window.location.pathname}${window.location.search}#${hash}`);
            moveCloseButtonInside();
        },
        afterSlideLoad: (slide) => {
            const slideContent = slide.querySelector('.ginlined-content');
            moveElement(slideContent, modalWrapper);
        },
    });

    // Open the modal automatically if URL hash is present
    if (window.location.hash === `#${hash}`) {
        modal.open();
    }

    // Power up the modal trigger
    if (modalTrigger) {
        modalTrigger.addEventListener('click', (e) => {
            e.preventDefault();
            modal.open();
        });
    }

    function moveElement(target, source) {
        target.innerHTML = '';
        target.appendChild(source);
    }

    function moveCloseButtonInside() {
        const closeBtn = document.querySelector('.gclose');
        const modalContainer = document.querySelector('.gslider');
        if (closeBtn && modalContainer) {
            modalContainer.appendChild(closeBtn);
        }
    }
}

function initUserNavigation(navigationTrigger, url) {
    const navigationContainer = navigationTrigger.parentNode;
    let isHover = false;

    navigationTrigger.href = url;

    navigationTrigger.addEventListener('touchstart', (e) => {
        if (isHover) {
            return;
        }

        e.preventDefault();
        navigationContainer.classList.add('hover');
        isHover = true;
    });

    document.addEventListener('touchstart', (e) => {
        if (isHover) {
            const isTouchInside = navigationContainer.contains(e.target);

            if (!isTouchInside) {
                navigationContainer.classList.remove('hover');
                isHover = false;
            }
        }
    });
}

export default function () {
    const el = document.querySelector('[data-header-user-panel]');

    if (!el) {
        return;
    }

    axios.get(el.dataset.headerUserPanel).then(response => {
        if (response.data.url) {
            const template = document.createElement('template');
            template.innerHTML = response.data.buffer.trim();
            el.after(template.content.firstElementChild);
            initUserNavigation(el, response.data.url);
        } else {
            const template = document.createElement('template');
            template.innerHTML = response.data.buffer.trim();
            document.body.appendChild(template.content.firstElementChild);
            el.dataset.loginRegistrationTrigger = '1';
            initLoginRegistration();
        }

        el.classList.remove('header__icon--disabled');
    }).catch(handleAjaxError)
}
