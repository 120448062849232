import counterUp from 'counterup2';

require('waypoints/lib/noframework.waypoints.min.js');

export default function () {
    [...document.querySelectorAll('[data-count]')].forEach((el) => {
        // eslint-disable-next-line no-new
        new window.Waypoint({
            element: el,
            handler() {
                counterUp(el, {
                    duration: 100,
                    delay: 1,
                });
                this.destroy();
            },
            offset: 'bottom-in-view',
        });
    });
}
