export default function () {
    function scrollToFirstInvalidFieldOrConfirmation(form) {
        if (!form) return;

        let headerOffset = 0;
        const offset = 30;
        const stickyHeader = document.querySelector('.sticky-header');
        const elementToSee = form.querySelector('p.error, p.confirm, .message:only-child');

        if (!elementToSee) return;

        const elementToSeeWrapper = elementToSee.parentNode;

        if (isElementInViewport(elementToSeeWrapper) === false) {
            if (stickyHeader) {
                headerOffset = stickyHeader.getBoundingClientRect().height;
            }

            window.scroll({
                top: elementToSeeWrapper.getBoundingClientRect().top + window.scrollY - headerOffset - offset,
                left: 0,
                behavior: 'smooth',
            });
        }
    }

    function isElementInViewport(el) {
        const rect = el.getBoundingClientRect();

        return (
            rect.top >= 0
            && rect.left >= 0
            && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
            && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    document.querySelectorAll('form').forEach((form) => {
        form.addEventListener('ajax_change', () => {
            scrollToFirstInvalidFieldOrConfirmation(form);
        });
    });
}
