import smoothscroll from 'smoothscroll-polyfill';
import { sentryWrapper } from '../../_helpers/sentry';
import { loadForElements } from '../../_helpers/module-loader';

import initCarousels from './components/carousels';
import initCart from './components/cart';
import initCounters from './components/counters';
import { initChangeBodyClassButtons, initChangeElementClassButtons } from './components/css-utils';
import initFaq from './components/faq';
import initFloatingLabel from './components/floating-label';
import initHeaderUserPanel from './components/header-user-panel';
import initInlineSvg from './components/inline-svg';
import initJuggler from './components/juggler';
import initLightbox from './components/lightbox';
import initRellax from './components/rellax';
import initScrollTo from './components/scroll-to';
import initScrollToAjaxMessage from './components/scroll-to-ajax-message';
import initSetCssProperty from './components/set-css-property';
import initSliders from './components/sliders';
import initStickyAddToCart from './components/sticky-add-to-cart';
import initStickyHeader from './components/sticky-header';

require('./components/polyfills');

// Smooth Scroll behavior polyfill
smoothscroll.polyfill();

sentryWrapper(() => {
    initCart();

    document.addEventListener('DOMContentLoaded', () => {
        // Header user panel has a priority
        initHeaderUserPanel();

        initCarousels();
        initChangeBodyClassButtons();
        initChangeElementClassButtons();
        initCounters();
        initFaq();
        initFloatingLabel();
        initInlineSvg();
        initJuggler();
        initLightbox();
        initRellax();
        initScrollTo();
        initScrollToAjaxMessage();
        initSetCssProperty();
        initSliders();
        initStickyAddToCart();
        initStickyHeader();

        loadForElements(document.querySelectorAll('[data-snippet]'), () => import('./components/syntax-highlighter'));
    });
});
