import { tns } from 'tiny-slider/src/tiny-slider';

export default function () {
    [...document.querySelectorAll('.slider-wrapper')].forEach((el) => {
        tns({
            container: el,
            items: 1,
            nav: false,
            mouseDrag: true,
            slideBy: 'page',
            gutter: 95,
            controlsPosition: 'bottom',
            controlsText: ['<i class="icon icon-chevron-left"></i>', '<i class="icon icon-chevron-right"></i>'],
        });
    });
}
