export default function () {
    document.querySelectorAll('.form-label-group').forEach((el) => {
        const input = el.querySelector('input.text');
        const label = el.querySelector('label');

        if (!input) return;

        const placeholder = input.getAttribute('placeholder');

        if (!placeholder && label) {
            input.setAttribute('placeholder', label.innerText);
        }
    });
}
