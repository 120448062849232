const Rellax = require('rellax');

export default function () {
    if (document.querySelectorAll('[data-rellax]').length > 0) {
        // eslint-disable-next-line no-new
        new Rellax('[data-rellax]', {
            center: true,
        });
    }
}
