export function initChangeBodyClassButtons() {
    [...document.querySelectorAll('[data-change-body-class]')].forEach((element) => {
        element.addEventListener('click', (event) => {
            const className = element.getAttribute('data-change-body-class');
            if (className) {
                event.preventDefault();

                if (className === 'show-cart' && !document.body.classList.contains(className)) {
                    window.CartObserver.showCart()
                } else {
                    document.body.classList.toggle(className);
                }
            }
        });
    });
}

export function initChangeElementClassButtons() {
    [...document.querySelectorAll('[data-change-element-class]')].forEach((element) => {
        element.addEventListener('click', (event) => {
            const className = element.getAttribute('data-change-element-class');
            const targetElement = document.querySelector(element.getAttribute('data-change-element'));

            if (className && targetElement) {
                event.preventDefault();
                targetElement.classList.toggle(className);
            }
        });
    });
}
